import React from "react";
import styled from "styled-components";

function Gallery({ imgSrc, gridColumn, gridRow, onClick }) {
  return (
    <GalleryItem
      gridColumn={gridColumn}
      gridRow={gridRow}
      onClick={onClick}
      className="w-full"
    >
      <Img src={imgSrc} alt="have error" />
    </GalleryItem>
  );
}

export default Gallery;

const GalleryItem = styled.div`
  grid-column: ${({ gridColumn }) => gridColumn};
  grid-row: ${({ gridRow }) => gridRow};
  position: relative;
  &::before {
    content: "";
    background-color: ${(props) => props.theme.color.blue};
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;

    opacity: 0;
    transition: all 0.3s;
  }
  &:hover::before {
    opacity: 0.6;
  }
`;

const Img = styled.img`
  width: 100%;
  height: 100%;
  object-fit: cover; /* Maintain aspect ratio */
`;
