import Header from "../components/Header";
import Team from "../components/team";

function team() {
  return (
    <div>
      <Header PathTo="Prijzen" />
      {/* <div className="py-36 "> */}
      <Team />
      {/* </div> */}
    </div>
  );
}

export default team;
