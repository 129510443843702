// start in styled component
import styled from "styled-components";
// start in transition
import { useTranslation } from "react-i18next";
// start in fontAwesome
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faWhatsapp } from "@fortawesome/free-brands-svg-icons";

function HeaderHome() {
  const { t } = useTranslation();
  return (
    <Section>
      <div className="container mx-auto md:px-44 px-2">
        <Div className="text-center md:w-80 w-full">
          <h4 className="text-lg mb-2 font-semibold">{t("headerHome")}</h4>
          <A
            href="https://wa.me/+31639064917"
            className="mt-2 mb-5 uppercase font-semibold text-xl flex items-center justify-center text-[#ec3d43]"
          >
            <span className="mr-2 text-xl ">
              <FontAwesomeIcon icon={faWhatsapp} />
            </span>
            {t("online")}
          </A>

          <Button className="mt-5">
            <A
              href={`${process.env.PUBLIC_URL}/Online_bokking.html`}
              className="mt-2 uppercase font-semibold text-xl flex items-center justify-center text-white"
            >
              Book Online
            </A>
          </Button>
        </Div>
      </div>
    </Section>
  );
}

export default HeaderHome;

const Section = styled.section`
  background-image: url("/image/catalogue-bg.jpg");
  width: 100%;
  height: 100%;
  background-size: cover;
  padding: 120px 0;
`;

const Div = styled.div`
  background-color: #fff;
  padding: 40px 0;
`;

const A = styled.a`
  /* color: ${(props) => props.theme.color.primary}; */
  margin: 0;
  font-size: 12px;
  letter-spacing: 2px;
`;

const Button = styled.button`
  background-color: ${(props) => props.theme.color.primary};
  color: ${(props) => props.theme.color.white};
  padding: 5px 10px;
  border-radius: 8px;
`;
