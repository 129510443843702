const theme = {
  color: {
    primary: "#ec3d43",
    gray: "#777",
    gold: "#b89035;",
    blue: "#1a4587",
    white: "#fff",
  },
};

export default theme;
