// start in component
import Header from "../components/Header";
// start in styled component
import styled from "styled-components";
// start in fontAwesome
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faHouse,
  faPhone,
  faEnvelope,
} from "@fortawesome/free-solid-svg-icons";
// start in transition
import { useTranslation } from "react-i18next";
import { height } from "@fortawesome/free-brands-svg-icons/fa42Group";

function Contact() {
  const { t } = useTranslation();
  return (
    <Section>
      <Header PathTo="Contact" />
      <Div className="mt-12">
        <div className="container mx-auto lg:px-36 px-8">
          <div className="grid grid-cols-12 gap-4">
            {/* start in contact */}
            <div className="col-span-12 lg:col-span-4">
              <iframe
                src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d152.40317470132163!2d4.8831451!3d52.3259676!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x47c60a14e708582f%3A0xe2bfa7fbc8d61f35!2sKastelenstraat%2067%2C%201083%20CC%20Amsterdam!5e0!3m2!1snl!2snl!4v1715522349924!5m2!1snl!2snl"
                allowfullscreen=""
                loading="lazy"
                referrerpolicy="no-referrer-when-downgrade"
                className="w-full h-full"
              ></iframe>
            </div>

            {/* start in form */}
            <form
              action="https://formspree.io/f/xzbnoqoo"
              method="POST"
              className="col-span-12 lg:col-span-8 flex flex-col justify-end"
            >
              <div className="flex flex-wrap md:flex-nowrap gap-4">
                <div className="flex flex-col w-full">
                  <Input
                    type="text"
                    name="name"
                    placeholder={t("contact.placeHome")}
                    className="h-10 p-4"
                  />
                  <Input
                    type="text"
                    name="email"
                    placeholder={t("contact.placeEmail")}
                    className="my-5 h-10 p-4"
                  />
                  <Input
                    type="text"
                    name="subject"
                    placeholder={t("contact.placeSubject")}
                    className="h-10 p-4"
                  />
                </div>
                <TextArea
                  rows="4"
                  cols="50"
                  className="p-4 w-full"
                  name="message"
                  placeholder={t("contact.message")}
                ></TextArea>
              </div>
              <div className="flex justify-end mt-5">
                <Button className="cursor-pointer text-center outline-none text-white text-xs font-medium px-9 uppercase">
                  {t("contact.button")}
                </Button>
              </div>
            </form>
          </div>
          <ul className="grid lg:grid-cols-3 md:grid-cols-2 grid-cols-1 lg:justify-between justify-start justify-items-start mt-8">
            <li className="flex gap-4 mb-5">
              <Span>
                <FontAwesomeIcon icon={faHouse} />
              </Span>
              <a href="https://maps.app.goo.gl/6gobMctBUhtTA6Vc8">
                <h1 className="mb-2 font-semibold text-base">
                  Kastelenstraat 67
                </h1>
                <P>1083 CC Amsterdam</P>
              </a>
            </li>

            <li className="flex justify-center gap-4 mb-5">
              <Span>
                <FontAwesomeIcon icon={faPhone} />
              </Span>
              <div>
                <h1 className="mb-2 font-semibold text-base">+31 6 39064917</h1>
                <P>sun :closed</P>
                <div className="">
                  <P className="mt-1">Mon - Wed: 10:00 am - 19:00</P>
                  <P className="mt-1">Thu: 10:00 am - 21:00</P>
                </div>
                <div className="">
                  <P className="mt-1">Fri - Sat: 10:00 am - 19:00</P>
                </div>
              </div>
            </li>

            <li className="flex justify-end gap-4 mb-5">
              <Span>
                <FontAwesomeIcon icon={faEnvelope} />
              </Span>
              <div>
                <h1 className="mb-2 font-semibold text-base">
                  info@kapsalonzuidster.nl
                </h1>
                <P>Send us your query anytime!</P>
              </div>
            </li>
          </ul>
        </div>
      </Div>
    </Section>
  );
}

export default Contact;

const Section = styled.section`
  background: #f9f9ff;
`;

const Div = styled.div`
  padding: 120px 0;
`;

const Span = styled.span`
  font-size: 20px;
  font-weight: 500;
  color: ${(props) => props.theme.color.primary};
`;

const P = styled.p`
  color: ${(props) => props.theme.color.gray};
`;

const Input = styled.input`
  border: 1px solid #ced4da;
  outline: none;
`;

const TextArea = styled.textarea`
  border: 1px solid #ced4da;
  outline: none;
`;

const Button = styled.button`
  display: inline-block;
  background: linear-gradient(to right, #ec3d43 0%, #dc1a20 51%, #ec3d43 100%);
  background-size: 200% auto;
  font-family: "Roboto", sans-serif;
  line-height: 50px;
  border-radius: 50px;
  border: 0;
  box-shadow: 0px 10px 30px rgba(187, 146, 54, 0.3);
  transition: all 0.3s ease 0s;
  &:hover {
    background: linear-gradient(
      to right,
      #dc1a20 0%,
      #ec3d43 51%,
      #dc1a20 100%
    );
  }
`;
