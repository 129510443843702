import styled from "styled-components";
// start in fontAwesome
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowRight } from "@fortawesome/free-solid-svg-icons";
// start in react router
import { NavLink } from "react-router-dom";

function Header({ PathTo }) {
  return (
    <Section>
      <Div className="banner relative h-[50vh]">
        <Section className="relative">
          <div className="absolute top-2/4 left-2/4 -translate-x-2/4 h-full text-white">
            <div className="flex gap-2 items-center">
              <h1 className="capitalize">
                <NavLink to="/">Home</NavLink>
              </h1>
              <span>
                <FontAwesomeIcon icon={faArrowRight} />
              </span>
              <h1 className="capitalize">
                <NavLink to={PathTo}></NavLink> {PathTo}
              </h1>
            </div>
            <h1 className="capitalize text-5xl">{PathTo}</h1>
          </div>
        </Section>
      </Div>
    </Section>
  );
}

export default Header;

const Div = styled.div`
  background-image: url("image/headerHomeMain.jpg");
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  &::after {
    content: "";
    position: absolute;
    top: 15px;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 3;
    width: 0;
    height: 0;
    border-right: 80px solid transparent;
    border-left: 80px solid transparent;
    border-top: 30px solid #ffffff;
  }

  &::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgb(0, 0, 0, 0.4);
  }
`;

const Img = styled.img`
  position: relative;
`;

const Section = styled.section`
  width: 100%;
  height: 100%;
`;
