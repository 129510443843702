// start in styled component
import styled from "styled-components";
// start in fontAwesome
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faFacebookF,
  faInstagram,
  faTiktok,
} from "@fortawesome/free-brands-svg-icons";
// start in transition
import { useTranslation } from "react-i18next";

function Footer() {
  const { t } = useTranslation();
  return (
    <Foot>
      <div className="container mx-auto lg:px-36 px-8 ">
        <div className="grid md:grid-cols-3 grid-cols-1">
          {/* 1 */}
          <div className="md:mt-0 mt-8">
            <H1 className=" text-white capitalize">contact:</H1>
            <div className="mt-4">
              <H2 className="capitalize font-bold">{t("footer.address")}</H2>
              <P>Kastelenstraat 67</P>
              <P>1083 CC Amsterdam</P>
            </div>
            <div className="mt-4">
              <H2 className="capitalize font-bold">tel:</H2>
              <P className="mt-2 uppercase font-semibold">+31639064917</P>
            </div>
            <div className="mt-4">
              <H2 className="capitalize font-bold">whatsapp:</H2>
              <A
                href="https://wa.me/+31639064917"
                className="mt-2 uppercase font-semibold"
              >
                +31639064917
              </A>
            </div>
            <div className="mt-4">
              <H2 className="capitalize font-bold">Book Online:</H2>
              <A
                href={`${process.env.PUBLIC_URL}/Online_bokking.html`}
                className="mt-2 uppercase font-semibold"
              >
                Visit
              </A>
            </div>
          </div>
          {/* 2 */}
          <div className="md:mt-0 mt-8">
            <H1 className=" text-white capitalize">{t("footer.h1Tow")}</H1>
            <div className="mt-4">
              <H2 className="capitalize font-bold">Facebook</H2>
              <a href="https://www.facebook.com/share/2WGmmn12jj3CnqTh/?mibextid=LQQJ4d">
                <Icon className="text-white">
                  <FontAwesomeIcon icon={faFacebookF} />
                </Icon>
              </a>
            </div>
            <div className="mt-4">
              <H2 className="capitalize font-bold">instagram</H2>
              <a href="https://www.instagram.com/zuidster?igsh=cGJ4Z29rY2U2Y3g%3D&utm_source=qr">
                <Icon className="text-white">
                  <FontAwesomeIcon icon={faInstagram} />
                </Icon>
              </a>
            </div>
            <div className="mt-4">
              <H2 className="capitalize font-bold">tik tok</H2>
              <a href="https://www.tiktok.com/@user161606869493?_t=8m7RTYigAOu&_r=1">
                <Icon className="text-white">
                  <FontAwesomeIcon icon={faTiktok} />
                </Icon>
              </a>
            </div>
          </div>
          {/* 3 */}
          <div className="md:mt-0 mt-8">
            <H1 className=" text-white capitalize">{t("footer.h1Three")}</H1>
            <div className="mt-3">
              <H2 className="capitalize font-bold">sun:</H2>
              <P>closed</P>
            </div>
            <div className="mt-3">
              <H2 className="capitalize font-bold">Mon - Wed:</H2>
              <P>10:00 am - 19:00</P>
            </div>
            <div className="mt-3">
              <H2 className="capitalize font-bold">Thu:</H2>
              <P>10:00 am - 21:00</P>
            </div>
            <div className="mt-3">
              <H2 className="capitalize font-bold">Fri - Sat:</H2>
              <P>10:00 am - 19:00</P>
            </div>
          </div>
        </div>
      </div>
    </Foot>
  );
}

export default Footer;

const Foot = styled.footer`
  background-color: #fff;
  padding: 120px 0;
  position: relative;

  &::before {
    content: "";
    background-image: url("image/BLUERedLine.png");
    background-repeat: no-repeat;
    width: 100%;
    height: 50px;
    position: absolute;
    top: 0;
    left: 0;
    filter: drop-shadow(0px 10px 8px rgb(0, 0, 0, 0.5));
  }
`;

const H1 = styled.h1`
  color: ${(props) => props.theme.color.blue};
  font-size: 22px;
  font-weight: 600;
  letter-spacing: 0.8px;
`;

const H2 = styled.h2`
  color: ${(props) => props.theme.color.primary};
`;

const P = styled.p`
  color: ${(props) => props.theme.color.gray};
`;

const A = styled.a`
  color: ${(props) => props.theme.color.gray};
`;

const Icon = styled.p`
  transition: all 0.3s;
  cursor: pointer;
  color: ${(props) => props.theme.color.blue};
  &:hover {
    color: ${(props) => props.theme.color.blue};
  }
`;
