import styled from "styled-components";
// start in component
import Title from "./shared/title";
// start in transition
import { useTranslation } from "react-i18next";

function Team() {
  const { t } = useTranslation();
  return (
    <Section>
      <div className="container mx-auto lg:px-36 px-8 mt-10">
        <Title textMain={t("teamH1")} textPar={t("teamP")} />
      </div>
    </Section>
  );
}

export default Team;

const Section = styled.section`
  padding: 120px 0;
`;
