import Header from "../components/Header";
import CardPrice from "../components/cardPrice";
// start in styled component
import styled from "styled-components";
// start in transition
import { useTranslation } from "react-i18next";

function Prices() {
  const { t } = useTranslation();
  return (
    <div>
      <Header PathTo={t("headerHomeText")} />
      <Div className="mt-">
        <H1 className="mb-12 text-center text-4xl capitalize">
          {t("price.mainH1")}
        </H1>
        <div className="container mx-auto lg:px-36 px-8">
          <div className="grid lg:grid-cols-3 sm:grid-cols-2 grid-cols-1 gap-8">
            <CardPrice
              main={t("price.card1.main")}
              paragraph={t("price.card1.paragraph")}
              price="€30,-"
              serviceOne={t("price.card1.serviceOne")}
              serviceTow={t("price.card1.serviceTow")}
              serviceThree={t("price.card1.serviceThree")}
              serviceFour={t("price.card1.serviceFour")}
              colorText="#ec3d43"
              colorTextTow="#ec3d43"
            />
            <CardPrice
              main={t("price.card2.main")}
              paragraph={t("price.card2.paragraph")}
              price="€45,-"
              serviceOne={t("price.card2.serviceOne")}
              serviceTow={t("price.card2.serviceTow")}
              serviceThree={t("price.card2.serviceThree")}
              serviceFour={t("price.card2.serviceFour")}
              serviceFive={t("price.card2.serviceFive")}
              serviceSex={t("price.card2.serviceSex")}
              bg="#222"
              colorText="#1a4587"
              colorTextTow="#4073c0"
            />
            <CardPrice
              main={t("price.card3.main")}
              paragraph={t("price.card3.paragraph")}
              price="€15,-"
              serviceOne={t("price.card3.serviceOne")}
              serviceTow={t("price.card3.serviceTow")}
              serviceThree={t("price.card3.serviceThree")}
              serviceFour={t("price.card3.serviceFour")}
              colorText="#ec3d43"
              colorTextTow="#ec3d43"
            />

            <CardPrice
              main={t("price.card4.main")}
              paragraph={t("price.card4.paragraph")}
              price="€25,-"
              serviceOne={t("price.card4.serviceOne")}
              serviceTow={t("price.card4.serviceTow")}
              serviceThree={t("price.card4.serviceThree")}
              serviceFour={t("price.card4.serviceFour")}
              colorText="#ec3d43"
              colorTextTow="#ec3d43"
            />

            <CardPrice
              main={t("price.card5.main")}
              paragraph={t("price.card5.paragraph")}
              price="€30,-"
              serviceOne={t("price.card5.serviceOne")}
              serviceTow={t("price.card5.serviceTow")}
              serviceThree={t("price.card5.serviceThree")}
              bg="#222"
              colorText="#1a4587"
              colorTextTow="#4073c0"
            />

            <CardPrice
              main={t("price.card6.main")}
              paragraph={t("price.card6.paragraph")}
              price="€5,-"
              serviceOne={t("price.card6.serviceOne")}
              serviceTow={t("price.card6.serviceTow")}
              colorText="#ec3d43"
              colorTextTow="#ec3d43"
            />

            <CardPrice
              main={t("price.card7.main")}
              paragraph={t("price.card7.paragraph")}
              price="€25,-"
              serviceOne={t("price.card7.serviceOne")}
              serviceTow={t("price.card7.serviceTow")}
              serviceThree={t("price.card7.serviceThree")}
              serviceFour={t("price.card7.serviceFour")}
              colorText="#ec3d43"
              colorTextTow="#ec3d43"
            />
          </div>
        </div>
      </Div>
    </div>
  );
}

export default Prices;

const H1 = styled.h1`
  color: ${(props) => props.theme.color.blue};
`;

const Div = styled.div`
  padding: 120px 0;
`;
